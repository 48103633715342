import React from 'react';
import {Paper} from "@mui/material";

const CardComponent = (props) => {
    return (
      <Paper elevation={1} {...props} style={{...props.style, ...styles.card}}>
          {props.children}
      </Paper>
    );
}

const styles = {
    card: {
        padding: '30px',
        margin: '30px'
    }
}

export {CardComponent};
