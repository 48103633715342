import React from "react";
import {LogoComponent} from "../../components/LogoComponent";
import {CardComponent} from "../../components/CardComponent";
import {Typography} from "@mui/material";
import {withRouter} from "../../services/withRouter";
import client from "../../services/request";
import {TextFieldComponent} from "../../components/TextFieldComponent";

class DeprecatedComponentPure extends React.Component {
    constructor(props) {
        super(props);
    }

    async _requestPassword({email}) {
        return client('post', '/forgot', {email});
    }

    render() {
        return (
            <>
                <header className={'content auth-content'}>
                    <LogoComponent/>
                    <CardComponent>
                        <Typography variant={'h5'} component={'h6'}>Recurso movido</Typography>
                        <br/>
                        <Typography>Por favor acesse <a target={'_blank'}
                                                        href={'https://passwordreset.microsoftonline.com'}>
                            https://passwordreset.microsoftonline.com</a>
                        </Typography>
                    </CardComponent>
                </header>
            </>
        );
    }
}

export const DeprecatedComponent = withRouter(DeprecatedComponentPure);
