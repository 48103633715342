import {TextFieldComponent} from "../../components/TextFieldComponent";
import {ErrorMessage} from "@hookform/error-message";
import {useState} from "react";
import {useForm} from "react-hook-form";
import {ButtonComponent} from "../../components/ButtonComponent";
import {Typography} from "@mui/material";
import {LoadingSend} from "../../components/LoadingSendComponent";

export const FormChangePassword = ({sendData, magicCode}) => {
    const [message, setMessage] = useState();
    const {register, handleSubmit, formState: {errors, isSunmitting}} = useForm();

    const onSubmit = async data => {
        const dataRequest = await sendData({
            newPassword: data.password,
            confirmPassword: data.confirmPassword,
            magicCode
        })
        setMessage(dataRequest.data.message);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {
                !isSunmitting ?
                    (
                        <>
                            <TextFieldComponent register={register} name={'password'}
                                                config={{
                                                    required: 'O campo senha é obrigatório',
                                                    pattern: {
                                                        message: 'Senha deve conter no minímo uma letra minúscula, uma maiúscula' +
                                                            ' e  um número',
                                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}/
                                                    },
                                                    minLength: {
                                                        value: 9,
                                                        message: 'A senha deve conter no minímo 8 caracteres'
                                                    }
                                                }}
                                                label={'Nova Senha'} type={'password'}/>

                            <TextFieldComponent register={register} name={'confirmPassword'}
                                                config={{
                                                    required: 'O campo confirmar senha é obrigatório'
                                                }}
                                                label={'Confirmar Senha'} type={'password'}/>

                            <ErrorMessage
                                name={'password'}
                                errors={errors}
                                render={({message}) =>
                                    <Typography textAlign={'center'}>{message}</Typography>}
                            />
                            <ErrorMessage
                                name={'confirmPassword'}
                                errors={errors}
                                render={({message}) =>
                                    <Typography textAlign={'center'}>{message}</Typography>}
                            />
                            <ButtonComponent type={'submit'}>Alterar</ButtonComponent>
                            <Typography textAlign={'center'}>{message}</Typography>
                        </>
                    ) : (<LoadingSend width={300} height={300}/>)
            }
        </form>
    )
}
