import React from 'react';
import client from "../../services/request";
import {LogoComponent} from "../../components/LogoComponent";
import {CardComponent} from "../../components/CardComponent";
import {Typography} from "@mui/material";
import {withRouter} from "../../services/withRouter";
import {FormChangePassword} from "./FormChangePassword";

class ChangePasswordComponentPure extends React.Component {
    constructor(props) {
        super(props);
    }

    async _changePassword({newPassword, confirmPassword, magicCode}) {
        return client('put', `/change/${magicCode}`, {newPassword, confirmPassword});
    }

    render() {
        return (
            <>
                <header className={'content auth-content'}>
                    <LogoComponent/>
                    <CardComponent>
                        <Typography variant={'h4'} component={'h5'} textAlign={'center'}>Recuperação de
                            senha</Typography>
                        <br/>
                        <FormChangePassword sendData={this._changePassword} magicCode={this.props.params.magiccode}/>
                    </CardComponent>
                    <div style={{backgroundColor: 'none', border: 'none'}}>
                        <ul style={{fontSize: 12, color: 'black'}}>
                            <b>Senha deve conter:</b>
                            <li>Senha deve conter letra maiúscula e minúscula</li>
                            <li>Número 0-9</li>
                            <li>No minímos 8 caracteres</li>
                            <li>Não deve ser igual a login de rede ou nome</li>
                        </ul>
                    </div>
                </header>
            </>
        )
    }
}

export const ChangePasswordComponent = withRouter(ChangePasswordComponentPure);
