import React from "react";
import {LogoComponent} from "../../components/LogoComponent";
import {FormRecover} from "./FormRecover";
import {CardComponent} from "../../components/CardComponent";
import {Typography} from "@mui/material";
import {withRouter} from "../../services/withRouter";
import client from "../../services/request";

class RecoverComponentPure extends React.Component {
    constructor(props) {
        super(props);
    }

    async _requestPassword({email}) {
        return client('post', '/forgot', {email});
    }

    render() {
        return (
            <>
                <header className={'content auth-content'}>
                    <LogoComponent/>
                    <CardComponent>
                        <Typography variant={'h5'} component={'h6'}>Recuperação de senha:</Typography>
                        <br/>
                        <FormRecover sendData={this._requestPassword} />
                    </CardComponent>
                </header>
            </>
        );
    }
}

export const RecoverPasswordComponent = withRouter(RecoverComponentPure);
