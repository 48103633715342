import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {TextFieldComponent} from "../../components/TextFieldComponent";
import {ButtonComponent} from "../../components/ButtonComponent";
import {ErrorMessage} from "@hookform/error-message";
import {LoadingSend} from "../../components/LoadingSendComponent";
import {Typography} from "@mui/material";

export const FormRecover = ({sendData, method}) => {
    const [message, setMessage] = useState();
    const {register, handleSubmit, formState: {errors, isSubmitting}} = useForm();
    const onSubmit = async data => {
        const dataRequest = await sendData({email: data.email})
        setMessage(dataRequest.data.message);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {
                !isSubmitting ?
                    (
                        <>
                            <TextFieldComponent register={register} name={'email'}
                                                config={{
                                                    required: "O campo email é obrigatório",
                                                    pattern: {
                                                        value: /@coopersystem.com.br/g,
                                                        message: "E-mail fora do padrão ou fora do domínio coopersystem."
                                                    }
                                                }}
                                                label="Email"/>

                            <ErrorMessage
                                name={'email'}
                                errors={errors}
                                render={({message}) => <p>{message}</p>}
                            />
                            <ButtonComponent type={'submit'}>Recuperar</ButtonComponent>
                            <Typography textAlign={'center'}>{message}</Typography>
                        </>
                    ) : (<LoadingSend height={300} width={300}/>)
            }
        </form>
    );
};
