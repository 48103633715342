import React from 'react';
import {TextField} from "@mui/material";

const TextFieldComponent = (props) => {
    return (
        <TextField
            {...props.register(props.name, props.config || null)}
            id={'outlined-basic'}
            {...props}
            fullWidth={true}
            style={styles.input}
        />
    )
}

const styles = {
    input: {
        margin: '10px'
    }
}

export {TextFieldComponent};
