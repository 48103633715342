import axios from "axios";

const client = async (method, pathUrl, data) => {
    return axios({
        method: method,
        url: `https://sso.coopersystem.com.br/api/v1${pathUrl}`,
        data: data
    });
}

export default client;
