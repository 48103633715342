import './App.css';

import React from "react";

import {BrowserRouter, Route, Routes} from "react-router-dom";
import {RecoverPasswordComponent} from "./screens/Recover/Recover";
import {ChangePasswordComponent} from "./screens/ChangePassword/ChangePassword";
import {DeprecatedComponent} from "./screens/Recover/deprecated";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={'/'} element={<DeprecatedComponent />} />
            </Routes>
        </BrowserRouter>
    )
}

export default App;
