import React from 'react';
import {LinkCustomComponent} from "./LinkCustomComponent";

const LogoComponent = ({width, height}) => {
    return (
        <LinkCustomComponent to={`/`}>
            <img src={'/logo-coopersystem.png'} width={width} height={height} />
        </LinkCustomComponent>
    );
};

export {LogoComponent};
